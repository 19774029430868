import HystModal from "./hystmodallib";
import "element-closest-polyfill";
import refsCommon from "../common/refsCommon";
import { setCookie, getCookie, deleteCookie } from "../common/cookie";

const { acceptBtnCookie, deleteBtnCookie } = refsCommon;

const onClickAccept = () => {
	// cookie accept logic
	setCookie("cookie_Catalyseur_de_Succès", "accepted", 1); // зберігаємо кукі 1 день

	myModal.close("#modalCookie");
};
const onClickDelete = () => {
	// cookie accept logic
	deleteCookie("cookie_Catalyseur_de_Succès"); //

	myModal.close("#modalCookie");
};

global.HystModal = HystModal;

const myModal = new HystModal({
	// for dynamic init() of modals
	// linkAttributeName: false,
	catchFocus: false,
	closeOnEsc: true,
	backscroll: true,
	closeOnOverlay: false,
	closeOnEsc: false,
	beforeOpen: function (modal) {
		// console.log('Message before opening the modal');
		// console.log(modal); //modal window object
	},
	afterClose: function (modal) {
		// console.log('Message after modal has closed');
		// console.log(modal); //modal window object

		//If Youtube video inside Modal, close it on modal closing
		let videoframe = modal.openedWindow.querySelector("iframe");
		if (videoframe) {
			videoframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', "*");
		}
	},
});

acceptBtnCookie.addEventListener("click", onClickAccept);
deleteBtnCookie.addEventListener("click", onClickDelete);

window.onload = function () {
	if (getCookie("cookie_Catalyseur_de_Succès") !== "accepted") {
		myModal.open("#modalCookie");
	}
};

// плавне закриття модального вікна по кліку на посилання всередині модалки.

// document.addEventListener("DOMContentLoaded", function () {
// 	document.querySelectorAll('.hystmodal a[href^="#"]').forEach((anchor) => {
// 		anchor.addEventListener("click", function (e) {
// 			e.preventDefault(); // Запобігає стандартній поведінці браузера
// 			const target = this.getAttribute("href");
// 			if (target === "#" || !target) {
// 				myModal.close();
// 				return;
// 			}
// 			// Закриваємо модалку
// 			myModal.close();

// 			// Затримка, щоб модалка встигла закритися, і тільки потім здійснити перехід
// 			setTimeout(() => {
// 				document.querySelector(target)?.scrollIntoView({
// 					behavior: "smooth",
// 				});
// 			}, 300); // 300 мс - час, протягом якого модалка закривається
// 		});
// 	});
// });
